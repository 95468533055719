<template>
  <v-container fill-height>
    <v-row class="justify-center">
      <v-col cols="12" sm="8" md="6" lg="5" xl="4">
        <v-card class="pa-6" color="surface">
          <div class="d-flex justify-center align-center mb-6">
            <v-responsive max-width="180">
              <v-img
                :src="
                  require(`@/assets/logo-${
                    $vuetify.theme.dark ? 'white' : 'black'
                  }.png`)
                "
                class="pointer-hand"
              />
            </v-responsive>
          </div>

          <v-form v-model="valid" ref="form" autocomplete="off">
            <div @keypress.enter.prevent="submit()">
              <v-text-field
                v-model="form.email"
                label="E-mail"
                background-color="accent"
                :rules="emailRules"
                solo
                flat
                @keydown.space.prevent
              />

              <v-text-field
                v-model="form.password"
                label="Senha"
                background-color="accent"
                type="password"
                :rules="rules"
                solo
                flat
                @keydown.space.prevent
              />

              <v-checkbox
                v-model="remember"
                label="Lembrar acesso"
                class="pt-0 mt-0 mb-2"
              />

              <v-btn
                color="gradient"
                block
                large
                dark
                @click.prevent="submit()"
              >
                Efetuar acesso
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { signIn } from "@/services/auth";
import { displayAlert } from "@/utils";

export default {
  name: "Auth",

  data() {
    return {
      loading: false,
      valid: false,
      remember: false,
      form: {
        email: "",
        password: "",
      },
    };
  },

  beforeMount() {
    const username = localStorage.getItem("ajfusername") || false;

    if (username) {
      this.form.email = username;
      this.remember = true;
    }
  },

  computed: {
    emailRules() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail precisa ser válido",
      ];
    },

    rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },

  methods: {
    ...mapActions(["setSignIn"]),

    // submit to api
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        if (this.remember) localStorage.setItem("ajfusername", this.form.email);
        else localStorage.removeItem("ajfusername");

        const payload = this.form;

        await signIn(payload).then((res) => {
          // commit user and token on storage
          this.setSignIn({
            user: res.body.user,
            token: res.body.token,
          });

          // redirect to main
          this.$router.push({ path: "/main" });
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    displayAlert
  },
};
</script>

<style>
</style>